// @ts-check
import { ButtonBase } from '@mui/material';

import useParam from 'Lib/hooks/useParam';

/**
 * Wraps a component and adds a query parameter to the url when clicked
 * @param {children} children
 * @param {string} name
 * @param {object} state Optional state object to pass to url.
 * @param {object} sx MUI styling object.
 * @returns
 */
export default function ModalTrigger({ children, name, state, onClick, sx }) {
    const modal = useParam('modal');

    const handleClick = () => {
        modal.setValue(name, state, 'push');
        if (onClick) onClick();
    };

    return (
        <ButtonBase onClick={handleClick} sx={sx}>
            {children}
        </ButtonBase>
    );
}

export function useModalTrigger() {
    return { ...useParam('modal', 'push') };
}
